.owasp pre {
  background-color: #1e1e1e;
  color: #865cd6;
  padding: 0.8rem;
  border-radius: 6px;
  overflow-x: auto;
  margin: 0.5rem 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.owasp .url {
  color: #5f29cc;
  text-decoration: underline;
}

.owasp .username,
.password {
  font-weight: bold;
  color: #5f29cc;
}

.owasp .dashboard_item {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  margin-bottom: 25px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.owasp .dashboard_item p {
  color: #5f29cc;
  font-weight: 500;
  font-size: 60px;
}

.owasp .dashboard_item--double {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 425px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.owasp h4 div a {
  font-size: 14px;
  color: #5f29cc;
  text-decoration: underline;
}
