.anexos {
  margin-top: 0.5rem;
}

.anexos__containner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  transition: 0.3s;
}

.anexos__containner:hover {
  border-color: #1677ff;
}

.anexos__containner--info {
  display: flex;
  justify-content: flex-start;
  color: #1677ff;
  align-items: center;
  cursor: pointer;
}

.anexos__containner--action {
  cursor: pointer;
  transition: 0.3s;
}

.anexos__containner--action:hover {
  transform: scale(1.2);
}

.anexos__containner--action:active {
  transition: 0.1s;
  transform: scale(0.8);
}

.anexos__containner--info__icon {
  margin-right: 1rem;
}
