@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: #f5f5fb; */
  background-color: #fff;
  overflow: hidden;
  color: #3d364d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hr {
  height: 1px;
  background-color: #e2e2e2;
  width: 100%;
  margin: 1rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
}

.d-flex {
  display: flex;
}
@page {
  /* size: auto; */
  margin: 0rem !important;
}

.inter-<uniquifier > {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

svg {
  display: inline;
}
