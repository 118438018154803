.relatorio_rot {
  margin: 1rem 2rem;
}

.relatorio_rot .capa,
.relatorio_rot .page1,
.relatorio_rot .page2,
.relatorio_rot .page3,
.relatorio_rot .page4,
.relatorio_rot .page5,
.relatorio_rot .page6,
.relatorio_rot .page7 {
  margin-top: 104px;
}

.relatorio_rot hr {
  border: none;
  border-top: 0.5px solid #a3a3a3;
  margin: 10px 0;
  opacity: 1;
}
.relatorio_rot .relatorio__rot-title {
  text-align: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 998;
  background-image: url("../../../../../assets/images/rot_poly.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 204px;
  height: 60px;
}

.relatorio_rot .relatorio__rot-title h1 {
  margin-top: 11px;
  color: #33ccff;
  font-weight: bold;
  font-size: 20px;
}

.relatorio_rot .relatorio__rot-title h3 {
  margin-top: -4px;
  color: #fff;
  font-weight: 300;
  font-size: 6px;
  letter-spacing: 0.7px;
}

.relatorio_rot .relatorio__seusdados-logorot {
  height: 16px !important;
}

.relatorio_rot .relatorio__seusdados-date {
  font-size: 10px;
  color: #333333;
}

.relatorio_rot .relatorio__seusdados-page {
  position: absolute;
  bottom: 12px;
  right: 56px;
  z-index: 999;
}

.relatorio_rot .relatorio__rot--itemtitle {
  font-size: 18px;
  color: #5f29cc;
}

.relatorio_rot .relatorio__rot--bloco {
  background-color: #5f29cc1a;
  border-radius: 10px;
  padding: 1.2rem 1rem;
}

.relatorio_rot .relatorio__rot--bloco .bloco_title {
  color: #5f29cc;
  font-weight: bold;
  font-size: 12px;
}

.relatorio_rot .relatorio__rot--bloco .bloco_content {
  color: #2b2e48;
  font-size: 12px;
}

.relatorio_rot .relatorio__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 998;
  height: 48px;
  background-color: #f1f1f1;
}

.relatorio_rot .relatorio__footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 2rem;
}

.local_processo_container {
  display: flex;
}

.local_processo_container .local_processo_container__areatitle {
  font-size: 16px;
  font-weight: bold;
}

.local_processo_container .local_processo_container__tree {
  margin-left: 2rem;
}

.local_processo_container .local_processo_container__tree .texto_bloco {
  background-color: #a3a3a31a;
  border-radius: 4px;
  padding: 8px 2rem;
  min-width: 200px;
  display: inline-block;
}

.local_processo_container
  .local_processo_container__tree
  .local_processo_container__tree--area
  span {
  font-size: 12px;
}

.local_processo_container
  .local_processo_container__tree
  .local_processo_container__tree--processo
  span {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  font-size: 12px;
}

.local_processo_container
  .local_processo_container__tree
  .local_processo_container__tree--subprocesso {
  margin-left: 2rem;
  font-size: 12px;
}

.relatorio_rot .local_processo__active--span {
  color: #5f29cc !important;
  background-color: #5f29cc1a !important;
}
.relatorio_rot .local_processo__active--icon {
  color: #5f29cc !important;
}

.relatorio_rot .relatorio__rot--content {
  background-color: #f1f1f1;
  border-radius: 2px 2px 10px 10px;
  width: 100%;
  padding: 2rem 1rem;
}

.relatorio_rot .relatorio__rot--content .content__titular {
  color: #5f29cc;
  font-size: 14px;
  font-weight: bold;
}

.relatorio_rot .relatorio__rot--content .content__hr {
  border-top: 0.5px solid #5f29cc !important;
  margin-bottom: 20px;
  margin-top: 13px;
}

.relatorio_rot .relatorio__rot--content .content__label {
  color: #5f29cc;
  font-size: 12px;
  font-weight: bold;
}

.relatorio_rot .relatorio__rot--content .content__text {
  color: #2b2e48;
  font-size: 12px;
}

.relatorio_rot .relatorio__rot--content .content__br {
  margin-bottom: 20px;
}

/* tabela */
.relatorio_rot .relatorio__rot--table {
  border: 0.5px solid #b3b3b3;
  border-radius: 10px;
}

.relatorio_rot .table__cell {
  border-bottom: 0.5px solid #b3b3b3;
  padding: 12px;
}

.relatorio_rot .table__cellnoborder {
  padding: 12px;
}
.relatorio_rot .table__cellleftborder {
  border-left: 0.5px solid #b3b3b3;
}

.pendente {
  color: #febe14;
}

.concluido {
  color: #008c76;
}

.em-andamento {
  color: #0368c8;
}
/* tabela */

/* historico */
.relatorio_rot .relatorio__rot--bordercontainer {
  border: 0.5px solid #b3b3b3;
  border-radius: 10px;
  padding: 12px;
}
/* historico */
