.analise {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.analise__multipla {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
}

.analise__content {
  margin: 0 0rem 0 1rem;
  /* width: 80%; */
}

@media screen and (max-width: 800px) {
  .analise {
    width: 100%;
    height: calc(100% - 5rem);
    display: flex;
    padding: 0 !important;
  }
  .analise__content {
    margin: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  .analise__content {
    margin: 0 !important;
  }
}
