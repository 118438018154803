.respostas__headerCap {
  display: flex;
  justify-content: left;
  align-items: center;
}

.respostas__headerCap--actions {
  margin-left: auto;
}

.respostas__tags {
  margin-left: 1rem;
}

.analise__respostas hr {
  margin: 0.5rem 0;
}

.respostas__normas {
  width: 100%;
  padding: 12px 16px;
  border-left: 1px solid #d9d9d9;
}

.respostas__normas h2 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.respostas__normas h2:first-of-type {
  margin-top: 0;
}

@media screen and (max-width: 800px) {
  .respostas__pergunta {
    width: 100% !important;
    min-width: 0 !important;
  }
}
