.tasks {
  /* margin-bottom: 4rem; */
}

.tasks h2 {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.task__arrow-right {
  color: #1677ff;
  font-size: 1.2rem;
}

.tasks .ant-row-center {
  justify-content: flex-start;
}

.task_card {
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.25) !important;

  border-radius: 25px !important;
}

.task_card .ant-card-actions {
  border-bottom-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.microtask_container {
  padding: 0.2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.microtask_container:hover {
  background-color: rgb(243, 242, 241);
}

.microtask_user {
  display: flex;
  align-items: center;
  color: #1677ff;
}

.avatar-task {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
