.editAnalise {
  width: 100%;
  height: 100%;
}

.editAnalise .ant-tabs-content-holder {
  overflow-x: hidden !important;
}

.editAnalise__tabs {
  width: 100%;
  height: calc(100% - 60px);
  margin: -1rem 0 0 0;
}

.editAnalise hr {
  margin: 0 0rem 1rem 0rem;
}
.editAnalise .ant-collapse-header {
  background-color: #f5f5fb;
}

.relatorio {
  padding: 3rem;
}

.relatorio .capa {
}

.relatorio .capa h3 {
  font-size: 1.5em;
  font-weight: normal;
}

.relatorio .capa .linha_capa {
  background-color: #b3b0f8;
  height: 3px;
  width: 80%;
  margin-top: 1rem;
  margin-left: -3rem;
}

.relatorio .capa .capa__title {
  font-size: 5em;
  font-weight: normal;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.relatorio .capa img {
  background-size: cover;
  width: 100%;
}
.relatorio .capa p {
  font-size: 200px;
  -webkit-text-stroke-width: 2.9px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: transparent;
  margin-top: -14rem;
  margin-left: 1rem;
}

.relatorio .page2 {
  width: 100%;
}

.relatorio .page2 .rows {
  margin-top: 14rem;
  width: 100%;
}

.relatorio .title2 {
  font-size: 70px;
  font-weight: normal;
  margin-top: 2rem;
}

.relatorio .rows {
  display: flex;
  margin-top: 10rem;
  height: fit-content;
  padding-right: 3rem;
}

.relatorio .rows .col1 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.relatorio .rows .col1 .col1_title {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.relatorio .rows .col1 b {
  font-size: 16px;
}

.relatorio .rows .col2 {
  width: 70%;
  padding-left: 3rem;
}

.relatorio .rows .col2 h4 {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 4rem;
}

.relatorio .rows .col2 p {
  font-size: 14px;
}
.relatorio .rows .col2 ul {
  font-size: 14px;
}

.relatorio .rows .col2 ul li {
  margin-bottom: 1rem;
}

.relatorio .page3 {
  width: 100%;
}

.relatorio .page3 .rows {
  margin-top: 4rem !important;
}

.relatorio .page4 {
  width: 100%;
}

.relatorio .page4 .rows {
  margin-top: 0rem !important;
}

.relatorio .page5 {
  width: 100%;
}

.relatorio .page5 .rows {
  margin-top: 0rem !important;
}

.relatorio .page6 {
  width: 100%;
}

.relatorio .page6 .rows_chart {
  display: flex;
  margin-top: 4rem;
}

.relatorio .page6 .rows_chart .rows_chart--col1 {
  width: 50%;
}

.relatorio .page6 .rows_chart .rows_chart--col1 p {
  font-size: 40px;
  padding-right: 1rem;
}

.relatorio .page6 .rows_chart .rows_chart--col2 {
  width: 50%;
}

.rows_chart--col2__nivel {
  margin-left: 9rem;
  margin-top: 5rem;
  transform: scale(1.3);
}

.page6 .page6_chart--bar {
  margin-top: 8rem;
}

.relatorio .page7 {
  display: flex;
  flex-direction: column;
}

.relatorio .page7 .capa_planoacao--bg {
  background: url("../../../../assets/images/bg_block_rel.png") center center;
  background-size: cover;
  width: 100%;
  margin-top: 2rem;
  height: 708px;
}

.relatorio__headerCap {
  font-size: 40px;
  background: url("../../../../assets/images/bg_block_rel.png") center center;
  background-size: cover;
  width: 100%;
  /* margin-left: -3rem; */
  margin-top: 2rem;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
}

.proximos-passos__containner {
  margin-top: 3rem;
}

.proximos-passos__containner p {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-weight: 300;
}

.proximos-passos__hr {
  height: 1px;
  background-color: #000;
  width: 100%;
}

.proximos-passos__containner b {
  font-size: 24px !important;
  margin-left: 3rem;
}

.relatorio .page8 {
  width: 100%;
}

.relatorio .page9 {
  width: 100%;
}

.page9 .address-info {
  margin-top: 4rem;
}

.page9 .address-info p {
  font-size: 24px !important;
  margin-bottom: 2rem;
}

.page9 .address-info p {
  font-size: 24px !important;
  margin-bottom: 3rem;
}
.page9 .address-info p span {
  margin-left: 2rem;
}

.page9 .address-info .address-info--icon {
  color: #7c78dd;
  transform: scale(1.2);
}

.page9 .rows {
  margin-top: 10rem !important;
}

.page9 .rows .col1 {
  width: 20%;
}
.page9 .rows .col2 {
  width: 80%;
}

.page9 .rows .col2 p {
  font-size: 24px;
}
.relatorio__seusdados-logo {
  margin-top: 2rem;
  height: 2rem;
  opacity: 0.5;
}

.respostas__containner .alert {
  background: #fffbe6;
  border: 1px solid #ffe58f;
  padding: 20px 24px;
  border-radius: 8px;
  margin-top: 2rem;
  font-size: 14px;
}
