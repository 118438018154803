.notification_dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  left: -1.1rem;
  top: 0.7rem;
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.notification_icon.shake {
  animation: shake 0.5s ease-in-out infinite;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: #000;
  width: 300px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 10px 0;
  padding: 0.5rem;
}

.notification-item:hover {
  background-color: #f2f2f2;
}
