.planoAcao {
}

.planoAcao h2 {
  margin-top: 2rem !important;
  margin-bottom: 1rem;
}

.planoAcao .planoAcao__btn {
  float: right;
}

.planoAcao .planoAcao__textarea {
  margin-top: -1.5rem;
}

.planoAcao__form {
  margin-top: 3rem;
}

.planoAcao__timeline {
  margin-bottom: -3rem;
}
