.timelineItem {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.timelineItem .timelineItem__description {
  margin-right: 0.5rem;
}

.timelineItem .timelineItem__date {
  color: #1677ff;
}

.timelineItem .timelineItem__icon {
  margin: -0.25rem 0 0 1rem;
}
