.task-columns {
  display: flex;
  width: 100%;
}

.task-column {
  width: 330px;
  overflow-y: auto;
  max-height: 75vh;
  padding: 0 2rem;
}

.task-column .task_card {
  margin: 1rem 0;

  border-radius: 20px !important;

  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.25) !important;
}
