.saveBtn {
  background-color: #5f29cc;
  color: #fff;
  border-radius: 50%;
  font-size: 1.5rem;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
  border: none;
  animation: slideInLeft 10s ease-out infinite;
  z-index: 990;
}

.saveBtn-text {
  width: fit-content !important;
  border-radius: 28px;
  padding: 0 1rem;
}

.saveBtn-text p {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.saveBtn:focus {
  outline: revert;
}

.saveBtn:hover {
  transition: 0.3s;

  transform: scale(1.1);
}

.saveBtn:active {
  transition: none;
  transform: scale(0.9);
}

@keyframes slideInLeft {
  0% {
    transform: translateY(0);
  }
  6% {
    transform: translateY(0);
  }
  7% {
    transform: translateY(-1.5em);
  }
  8% {
    transform: translateY(0);
  }
  9% {
    transform: translateY(-1em);
  }
  10%,
  100% {
    transform: translateY(0); /* Parada até o próximo ciclo */
  }
}
