.clientes {
  width: 100%;
  height: 100vh;
}

.clientes__list {
  width: 100%;
  cursor: pointer;
}

.clientes__actions--search {
  max-width: 50rem;
}

.clientes__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  /* margin-top: 2rem; */
}
