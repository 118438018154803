.main {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background: #f5f0ff;
}

.main__content {
  /* padding: 1rem 2rem; */
  overflow-y: auto;
  width: 100%;
  /* margin-left: 257px; */
  height: calc(100%);
  /* transition: 0.3s; */
}

.main__content--noPadding {
  overflow-y: scroll;
  /* margin-top: 55px; */
  width: 100%;
  /* margin-left: 257px; */
  height: calc(100%);
  /* transition: 0.3s; */
}

.main__content--alert {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
}

.spinContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinContainer button {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
}

@media screen and (max-width: 800px) {
  .main__content {
    margin-left: 0 !important;
  }
  .main__content--alert {
    bottom: 1rem;
    right: 5% !important;
    width: 90%;
  }
  .main__content--noPadding {
    margin-left: 0 !important;
  }
}

.progress-bar {
  position: absolute;
  width: 100%;
  height: 5px; /* Altura da barra de progresso */
  top: 0px;
  z-index: 999;
  /* margin-bottom: -55px; */
}

.progress {
  height: 100%;
  background-color: #a7a7a7; /* Cor da barra de progresso */
  transition: width 0.5s ease-in-out;
}
