.ficha .ant-tabs-nav {
  margin: 0 0 0px 0;
}

.ficha .ficha__rightIcon {
  margin-top: 10px;
}

.ficha .ficha__index {
}

.ficha .ficha__description {
}

.ficha .ant-collapse-header {
  background: transparent !important;
  color: #000 !important;
}

.ficha .ant-collapse-content {
  border: none;
  background: transparent;
}

.ficha .collapse_titular .ant-collapse-item {
  background: transparent !important;
}
.ficha .collapse_titular .ant-collapse-expand-icon {
  color: #5f29cc !important;
}

.ficha .ficha__log {
  display: flex;
  align-items: center;
}

.ficha .ficha__log .ficha__log--date {
  color: #1677ff;
  margin-right: 0.5rem;
}

.ficha__log--desc {
  color: #00000080;
  white-space: pre-wrap;
}

.ficha__log--user {
  color: #b3b3b3;
}

.ficha .grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.ficha .ant-collapse {
  border-width: 0px !important;
  background-color: transparent !important;
}

.ficha .collapse_etapas .ant-collapse-header-text {
  color: #fff !important;
}
.ficha .collapse_etapas .ant-collapse-expand-icon {
  color: #fff !important;
}

.ficha .collapse_etapas .ant-collapse-header {
  background: #5f29cc !important;
  border-radius: 16px !important;
  color: #fff !important;
  padding: 0.5rem !important;
  border: none;
}

.ficha .collapse_titular {
  margin-left: -1.4rem;
}

.ficha .collapse_etapas .ant-collapse-content {
  border: none;
  background: transparent;
}

.ficha .collapse_etapas .ant-collapse-item {
  background: #5f29cc1a !important;
  border-radius: 16px !important;
  border: none;
  margin-bottom: 1rem;
}

.ficha .risco-pop {
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
}
.ficha .risco-pop:hover {
  background-color: #ededed;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .ficha .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
