.relatorio_questionario {
  padding: 3rem;
}

.relatorio_questionario .capa h3 {
  font-size: 1.5em;
  font-weight: normal;
}

.relatorio_questionario .capa .linha_capa {
  background-color: #b3b0f8;
  height: 3px;
  width: 80%;
  margin-top: 1rem;
  margin-left: -3rem;
}

.relatorio_questionario .capa .capa__title {
  font-size: 5em;
  font-weight: normal;
  margin-bottom: 2rem;
}
.relatorio_questionario .capa img {
  background-size: cover;
  width: 100%;
}
.relatorio_questionario .capa p {
  font-size: 200px;
  -webkit-text-stroke-width: 2.9px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: transparent;
  margin-top: -14rem;
  margin-left: 1rem;
}

.relatorio_questionario .page2 {
  width: 100%;
}

.relatorio_questionario .page2 .rows {
  margin-top: 7rem;
  width: 100%;
}

.relatorio_questionario .title2 {
  font-size: 70px;
  font-weight: normal;
  margin-top: 2rem;
}

.relatorio_questionario .rows {
  display: flex;
  margin-top: 10rem;
  height: fit-content;
  padding-right: 3rem;
}

.relatorio_questionario .rows .col1 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.relatorio_questionario .rows .col1 .col1_title {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.relatorio_questionario .rows .col1 b {
  font-size: 16px;
}

.relatorio_questionario .rows .col2 {
  width: 70%;
  padding-left: 3rem;
}

.relatorio_questionario .rows .col2 h4 {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 4rem;
}

.relatorio_questionario .rows .col2 p {
  font-size: 14px;
}
.relatorio_questionario .rows .col2 ul {
  font-size: 14px;
}

.relatorio_questionario .rows .col2 ul li {
  margin-bottom: 1rem;
}

.relatorio_questionario .page3 {
  width: 100%;
}

.relatorio_questionario .page3 .rows {
  margin-top: 28rem !important;
}

.relatorio_questionario .page4 {
  width: 100%;
}

.relatorio_questionario .page4 .rows {
  margin-top: 12rem;
}

.relatorio_questionario .page5 {
  width: 100%;
}

.relatorio_questionario .page5 .rows {
  margin-top: 21rem;
}

.relatorio_questionario .page6 {
  width: 100%;
}

.relatorio_questionario .page6 .rows {
  margin-top: 36rem;
}

.relatorio_questionario .page7 {
  width: 100%;
}

.relatorio_questionario .page7 .rows {
  margin-top: 4rem !important;
}

.relatorio_questionario .page8 {
  width: 100%;
}

.relatorio_questionario .page8 .rows {
  margin-top: 11rem !important;
}

.relatorio_questionario .page9 {
  width: 100%;
}

.relatorio_questionario .page9 .rows {
  margin-top: 3rem !important;
}

.relatorio_questionario .page10 {
  width: 100%;
}

.relatorio_questionario .page10 .rows {
  margin-top: 30rem !important;
}

.relatorio_questionario .page11 {
  width: 100%;
}

.relatorio_questionario .page11 .rows {
  margin-top: 37rem !important;
}
.relatorio_questionario .page12 {
  width: 100%;
}

.relatorio_questionario .page12 .rows_chart {
  display: flex;
  margin-top: 5rem;
  width: 100%;
}

.relatorio_questionario .page12 .rows_chart .rows_chart--col1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.relatorio_questionario .page12 .rows_chart .rows_chart--col1 p {
  font-size: 30px;
  text-align: center;
}

.relatorio_questionario .page12 .rows_chart .rows_chart--col2 {
  width: 50%;
}

.rows_chart--col2__nivel {
  margin-left: 9rem;
  margin-top: 5rem;
  transform: scale(1.3);
}

.page11 .page6_chart--bar {
  margin-top: 8rem;
}

.relatorio_questionario .page16 {
  width: 100%;
}

.relatorio_questionario .page17 {
  width: 100%;
}

.relatorio_questionario .page17 .address-info {
  margin-top: 4rem;
  margin-left: -1.5rem;
}

.relatorio_questionario .page17 .address-info p {
  font-size: 24px !important;
  margin-bottom: 2rem;
}

.relatorio_questionario .page17 .address-info p {
  font-size: 24px !important;
  margin-bottom: 3rem;
}
.relatorio_questionario .page17 .address-info p span {
  margin-left: 2rem;
}

.relatorio_questionario .page17 .address-info .address-info--icon {
  color: #7c78dd;
  transform: scale(1.2);
}

.relatorio_questionario .page17 .rows {
  margin-top: 10rem !important;
}

.relatorio_questionario .page17 .rows .col2 p {
  font-size: 24px;
}

.relatorio_questionario .relatorio__headerCap {
  font-size: 40px;
  background: url("../../../../../assets/images/bg_block_rel.png") center center;
  background-size: cover;
  width: 100%;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
}

.relatorio_questionario .proximos-passos__containner {
  margin-top: 3rem;
}

.relatorio_questionario .proximos-passos__containner p {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-weight: 300;
}

.relatorio_questionario .proximos-passos__hr {
  height: 1px;
  background-color: #000;
  width: 100%;
}

.relatorio_questionario .proximos-passos__containner b {
  font-size: 24px !important;
  margin-left: 3rem;
}

.relatorio_questionario .relatorio__seusdados-logo {
  margin-top: 2rem;
  height: 2rem;
  opacity: 0.5;
}

.relatorio_questionario .respostas__containner .alert {
  background: #fffbe6;
  border: 1px solid #ffe58f;
  padding: 20px 24px;
  border-radius: 8px;
  margin-top: 2rem;
  font-size: 14px;
}

.relatorio_questionario .tabela {
  width: 500px;
  display: flex;
  flex-direction: column;
  height: 230px;
  margin: -30px 0 0 80px;
  pointer-events: none;
  z-index: 0;
  opacity: 0.65;
}

.relatorio_questionario .linha {
  flex: 1;
  display: flex;
}

.relatorio_questionario .celula {
  flex: 1;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;

  padding: 8px;
  text-align: center;
  color: #373737;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relatorio_questionario .ponto {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
  margin: 5px;
  cursor: pointer;
  color: #ffffff;
}
