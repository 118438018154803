.form-questionario {
  overflow-y: auto;
  height: 69.3vh;
  padding-right: 1rem;
}

.form-questionario h2 {
  margin-bottom: 1rem;
}

.formContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.formItem {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 4px;
  font-size: 1rem;
}

.submitButton {
  background-color: #1890ff;
  color: #fff;
  border: none;
  padding: 12px 16px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2rem;
}

.saveButton {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
}

.analise__progress {
  margin: 1rem 2% -1rem 2%;
  width: 95%;
}

/* estilo para tempo de resposta expirado */
.questionario_fornecedor_expirado {
  background-image: url("../../../../../assets/images/meusdados_bg_paisagem.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 3;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

.questionario_fornecedor_expirado .message {
  height: 550px;
  width: 800px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}
