.meusdados {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.meusdados__bgblock {
  width: 30%;
  height: 100%;
  background-image: url("../../../assets/images/meusdados_bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.25);
}

.meusdados__formContainer {
  margin-left: 5rem;
  margin-top: 5rem;
}

.meusdados__formContainer h1 {
  font-size: 3rem;
}

.meusdados_logo {
  height: 35px;
  margin-top: 1rem;
  margin-left: 10%;
}

.meusdados__bgblock p {
  color: #fff;
  font-size: 2.6rem;
  font-weight: bold;
  width: 80%;
  margin-left: 10%;
  margin-top: 7rem;
}

@media screen and (max-width: 1200px) {
  .meusdados__bgblock p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .meusdados__bgblock p {
    font-size: 1.8rem;
  }
}

.meusdados_form {
  margin-top: 5rem;
}

@media screen and (max-width: 800px) {
  .meusdados {
    width: 100%;
    height: 100vh;
    overflow-x: scroll !important;
    overflow: auto !important;
    display: flex;
    flex-direction: column;
  }

  .meusdados__bgblock {
    width: 100%;
    height: fit-content;
    background-image: url("../../../assets/images/meusdados_bg.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);
  }

  .meusdados__formContainer {
    width: 80%;
    margin-left: 10%;
    margin-top: 3rem;
    padding-bottom: 6rem;
  }

  .meusdados__formContainer h1 {
    font-size: 2.5rem;
  }

  .meusdados_logo {
    height: 35px;
    margin-top: 1rem;
    margin-left: 10%;
  }

  .meusdados__bgblock p {
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    width: 80%;
    margin-left: 10%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .meusdados_form {
    margin-top: 3rem;
  }
}
