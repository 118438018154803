.guias-mapeamento-resumo hr {
  opacity: 1;
}

.guias-mapeamento-resumo .link-btn {
  cursor: pointer;
  transition: 0.3s;
}

.guias-mapeamento-resumo .link-btn:hover {
  color: #33ccff;
}

.guias-mapeamento-resumo .link-btn:active {
  opacity: 0.3;
}
