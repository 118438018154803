.analise__indice {
}

.analise__indice .ant-collapse-content-box {
  padding: 0 !important;
}

.indice__area {
  padding: 12px 16px 12px 16px;
}

.analise__indice--title {
  padding: 12px 16px;
  border-bottom: 1px solid #d9d9d9;
  background-color: rgba(0, 0, 0, 0.02);
  font-size: 14px;
}

.analise__indice--itens {
  overflow-y: auto;
  height: 72.5vh;
}

.analise__indice--cap {
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.analise__indice--cap:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.analise__indice--cap__active {
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  padding: 0.5rem;
  color: #5f29cc;
  background-color: #f7f0ff;
}
