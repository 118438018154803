.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.d-f {
  display: flex;
}

.j-b {
  justify-content: space-between;
}
.j-r {
  justify-content: flex-end;
}

.a-c {
  align-items: center;
}
hr {
  opacity: 0.2;
  margin-bottom: 1.4rem;
}

h1 {
  font-weight: bold;
  font-size: 42px;
}

h2 {
  font-weight: 600;
  font-size: 26px;
}

h3 {
  font-weight: 600;
  font-size: 24px;
}

h4 {
  font-weight: 600;
  font-size: 22px;
}

h5 {
  font-weight: 600;
  font-size: 20px;
}

p {
  color: #333333;
  font-weight: 500;
}

.ant-tabs {
  height: 100%;
}

.ant-tabs-content-holder {
  overflow: auto;

  display: flex;
}

.ant-upload {
  padding: 2px 15px !important;
}

.small-dragger span {
  display: flex;
  align-items: center;
}

.small-dragger span p {
  margin-left: 0.8rem;
}

.input_dis_text {
  color: rgb(64, 150, 255);
  font-size: 1rem;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.ant-btn-default {
  border: none !important;
  background: #f1f1f1 !important;
}

.ant-btn-sm {
  font-size: 0.8rem !important;
  border-radius: 100px !important;
}

.ant-select-selector {
  border-radius: 100px !important;
}

.ant-select-item-option-content {
  white-space: unset !important;
}

.ant-input-sm {
  border-radius: 100px !important;
}

.tbl-header-roxo .ant-table-thead {
  background-color: #5f29cc !important;
}

.tbl-header-roxo .ant-table-thead th {
  background-color: transparent !important;
  color: #fff !important;
}

.tbl-header-roxo .ant-table-thead td {
  font-weight: normal;
}
