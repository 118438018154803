.drawer {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 280px;
  height: 100vh;
  background-color: #5f29cc;
  padding: 1.3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 55px; */
  z-index: 1;
  position: fixed;
  /* -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25); */
  border-right: 1px solid #e2e2e2;
  overflow-y: auto;
}

.drawer-collapsed {
  width: 80px;
}

.ant-menu {
  background-color: #5f29cc !important;
  margin-top: 2rem;
  color: #fff;
  margin-left: -6px;
}

.drawer h1 {
  font-size: 2rem;
  line-height: 2.5rem;
}

.drawer .logo {
  height: 30px;
}

.drawer-collapsed .logo {
  display: none;
}

.collapseIcon {
  align-self: flex-end;
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #fff;
  position: relative;
  top: -2.2rem;
  display: none;
}

.drawer-collapsed .collapseIcon {
  align-self: center;
  margin-right: 0;
  display: block;
  top: 0rem;
}

.drawer .hr {
  /* border: 3px solid black; */
  width: 280px;
  background-color: #c0c0c0;
  height: 1px;
}

.drawer-collapsed .hr {
  width: 80px;
}

.drawer__darkbg {
  display: none;
}

@media screen and (max-width: 800px) {
  .drawer__darkbg--dnone {
    display: none;
  }
  .drawer {
    position: absolute !important;
    margin-top: 0;
    z-index: 999;
    display: flex;
  }
  .drawer-collapsed {
    display: none;
  }

  .drawer__darkbg {
    display: block;
    background-color: #000;
    width: calc(100% - 229px);
    position: absolute;
    opacity: 0.4;
    z-index: 998;
    height: 100vh;
    margin-left: 229px;
  }
}

.ant-menu-item-selected {
  background: #ffffff66 !important;
  border-radius: 0px 100px 100px 0px !important;
}

.ant-menu-item-group-title {
  color: #fff !important;
}

.ant-menu-submenu-title {
  color: #fff !important;
}

.ant-menu-item-divider {
  border-color: rgb(255, 255, 255, 0.06);
}

.ant-menu .ant-menu-submenu-disabled {
  color: rgba(255, 255, 255, 0.25) !important;
}
