.usuarios {
  width: 100%;
  height: 100%;
}

.usuarios__list {
  width: 100%;
}

.usuarios__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.usuarios__actions--search {
  max-width: 50rem;
}
