.relatorio_ripd {
  margin: 1rem 2rem;
}

.relatorio_ripd ul {
  list-style-type: disc;
}

.relatorio_ripd ol {
  list-style-type: decimal;
}

.relatorio_ripd .relatorio__ripd-title {
  margin-bottom: 2rem;
}

.relatorio_ripd .relatorio__ripd-title h1 {
  color: #5f29cc;
  font-weight: bolder;
}

.relatorio_ripd .relatorio__ripd-title h3 {
  margin-top: -15px;
  color: #38b6ff;
  font-weight: bolder;
  font-size: 1.3rem;
}

.relatorio_ripd .bloco_info {
  font-size: 12px;
}
.relatorio_ripd .bloco_info p {
  font-size: 12px;
}

.relatorio_ripd .bloco_info h5 {
  width: fit-content;
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  padding: 0.6rem 1.8rem;
  background-color: #5f29cc;
  color: #fff;
}

.relatorio_ripd .bloco_info .bloco_info--box {
  border: 1px solid #5f29cc;
  padding: 0.6rem 1.8rem;
}

.relatorio_ripd .bloco_info .bloco_info--box p span {
  color: #38b6ff;
}

.relatorio_ripd .relatorio__seusdados-logo {
  position: fixed !important;
  bottom: 1rem !important;
  height: 1.5rem !important;
  opacity: 1 !important;
  z-index: 999;
}

.relatorio_ripd .tabela {
  width: 500px;
  display: flex;
  flex-direction: column;
  height: 230px;
  margin: -30px 0 0 80px;
  pointer-events: none;
  z-index: 0;
  opacity: 0.65;
}

.relatorio_ripd .linha {
  flex: 1;
  display: flex;
}

.relatorio_ripd .celula {
  flex: 1;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;

  padding: 8px;
  text-align: center;
  color: #373737;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relatorio_ripd .ponto {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
  margin: 5px;
  cursor: pointer;
  color: #ffffff;
}
