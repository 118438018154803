.back-route {
  display: flex;
  justify-content: left;
  align-items: center;
  /* margin-top: 1rem;
  margin-bottom: 3rem; */
  height: 60px;
  margin-top: -1rem;
}

.back {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  /* opacity: 0.4; */
  cursor: pointer;
}

.back-route h2 {
  font-weight: 200;
  margin-top: -0.2rem;
}

@media screen and (max-width: 800px) {
  .back-route h2 {
    font-size: 1.2rem;
  }
}
