.intro-2 {
  width: calc(100% + 4rem);
  height: calc(100% + 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: -4.5rem 0 0 -2rem;
  overflow-y: auto;
}

.intro-2 h1 {
  font-size: 4rem;
  margin-top: 10%;
  max-width: 900px;
  text-align: left;
}

.intro-2 h3 {
  font-size: 1rem;
  max-width: 900px;
  text-align: justify;
}

@media (max-width: 768px) {
  .intro-2 h1 {
    font-size: 1.8rem;
    margin-top: 10%;
    text-align: center;
    padding: 0 1rem;
  }

  .intro-2 h3 {
    padding: 0 1rem;
    font-size: 1rem;
    text-align: justify;
  }
  .intro-2 {
    height: fit-content;
  }
}

.btn-next {
  margin: 20px auto;
  display: block;
}

.intro-2 .btn-next {
  background-color: #fff;
  color: #1677ff;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial;
  border: 0;
  border-radius: 0;
  padding: 2.3rem 2rem;
  font-weight: bold;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  border: 1px solid #1677ff;
}
