.editClientes {
  width: 100%;
  height: 100vh;
}

.capitulos-opt {
  transition: 0.3s;
  padding: 0.3rem 1rem;
  border: 0.5px solid rgb(210, 210, 210);
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}

.capitulos-opt:hover {
  background-color: #e5e5e5;
}

.area-opt {
  transition: 0.3s;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;

  border-radius: 0.5rem;
  border: 0.5px solid rgb(210, 210, 210);
}

.area-opt:hover {
  background-color: #e5e5e5;
}

.containner-questionario {
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  border: 0.5px solid rgb(210, 210, 210);
  margin-bottom: 1rem;
}
