.clientes__actions--right {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 1rem;
}

.task-collapse .ant-collapse-content {
  padding: 2.5rem 0rem 0 2.5rem;
  background-color: #f5f5fb;
}

.step_custom {
  background: #5f29cc22;
  color: #5f29cc;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.step_content_custom {
  border-left: 1px solid #5f29cc22;
  padding-left: 4rem;
  margin-left: 1rem;
  margin-top: -0.5rem;
  margin-bottom: 0.85rem;
}

.step_content_custom--noborder {
  border-left: 1px solid transparent;
  padding-left: 4rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 5rem;
}
.step_title_custom {
  position: relative;
  top: -2.1rem;
  left: 3rem;
}

.viewQuestionario {
  overflow-x: hidden;
  overflow-y: hidden;
}
