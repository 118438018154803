.Header {
  /* background-color: #5f29cc; */
  /* -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25); */
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* background-image: url("../../assets/images/header_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat; */
  background: #f5f0ff;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 2rem;
}

@media screen and (max-width: 800px) {
  .Header {
    background-size: auto;
  }
}

.Header__right {
  display: flex;
  color: #000;
  align-items: center;
}

.Header__right .Header__right--exit {
  margin-right: 2rem;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ff5029;
}

.Header__right .Header__right--line {
  height: 70px;
  width: 1px;
  background-color: #000;
  margin-left: 2rem;
}

.Header__right .Header__right--nivel {
  /* color: #000; */
  /* background-color: transparent !important; */
  margin-left: 0.5rem;
  font-size: 0.6rem;
  font-weight: bold;
}

.openmenuMobile {
  display: none;
}

.Header__right--empresa {
  opacity: 0.5;
  font-size: 8px;
}

@media screen and (max-width: 800px) {
  .openmenuMobile {
    display: block;
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
    cursor: pointer;
    transition: 0.1s;
  }
  .openmenuMobile:active {
    opacity: 0.2;
  }
  .header_logo {
    margin-left: 1rem;
  }
  .Header__right p {
    display: none;
  }
  .Header__right--line {
    display: none;
  }
  .Header__right--exit {
    margin: 0 1rem !important;
  }
}
