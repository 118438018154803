.analise__metrics {
  display: flex;
  justify-content: space-around;
  width: calc(100% -1rem);
  margin-top: -0.5rem;
  margin-bottom: -1rem;
  align-items: center;
  padding: 2rem;
  overflow-x: auto;
}

.analise__metrics h2 {
  margin: 0;
}
.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 7px 5px 0;
  border-color: transparent #000000 transparent transparent;
  position: relative;
}

.right {
  transform: rotate(180deg);
}

@media screen and (max-width: 800px) {
  .analise__metrics {
    flex-direction: column;
  }
}
