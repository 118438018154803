.formRespostas {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.indice {
  width: fit-content;
  background-color: #f5f5fb;
  padding: 1.3rem 2rem;
  border-right: 1px solid #e2e2e2;
  overflow: auto;
}

.indice__title {
  margin-bottom: 2rem;
}

.formRespostas__form {
  padding: 1.3rem 2rem;
  overflow: auto;
  width: 100%;
}

.formRespostas__form--title {
  margin-bottom: 2rem;
}

.formRespostas .perguntaCheck {
  position: relative;
  top: -3.25rem;
  left: 13rem;
}
