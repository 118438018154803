.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 7px 5px 0;
  border-color: transparent #000000 transparent transparent;
  position: relative;
}

.right {
  transform: rotate(180deg);
}

.custom-grid line {
  stroke-width: 1px;
}

.analise__metrics .tabela {
  width: 500px;
  display: flex;
  flex-direction: column;
  height: 230px;
  margin: -30px 0 0 80px;
  pointer-events: none;
  z-index: 0;
  opacity: 0.8;
}

.analise__metrics .linha {
  flex: 1;
  display: flex;
}

.analise__metrics .celula {
  flex: 1;
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;

  padding: 8px;
  text-align: center;
  color: #373737;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analise__metrics .ponto {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
  margin: 5px;
  cursor: pointer;
  color: #ffffff;
}
