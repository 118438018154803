.responder_questionario {
  margin-top: -2rem;
}

.checkboxGroup {
  margin-left: 1rem;
}

.responder_questionario_obs {
  min-width: 250px;
  width: 250px;
  height: 100%;
  background-color: transparent;
  margin-left: 1rem;
  overflow-y: hidden;
}

.analise__progress {
  width: 95%;
}

.form-questionario--mapeamento {
  overflow-y: auto;
  height: 68.3vh;
  padding-right: 1rem;
}
