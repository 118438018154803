.respostas__headerCap {
    display: flex;
    justify-content: left;
    align-items: center;
}

.respostas__headerCap--actions {
    margin-left: auto;
}

.respostas__tags {
    margin-left: 1rem;
}

.analise__respostas hr {
    margin: 0.5rem 0;
}

.respostas__containner {
    display: flex;
    width: 100%;
}

.respostas__pergunta {
    min-width: 400px;
}

.respostas__pergunta--label {
    margin: 1.5rem 0 0.5rem 0;
}

.respostas__pergunta--label__conforme {
    color: #43cb03;
}

.respostas__pergunta--label__inconforme {
    color: #fe0c0c;
}

.respostas__pergunta--label__blue {
    color: #0c8dfe;
}

.respostas__pergunta--label__orange {
    color: #fec10c;
}

.respostas__pergunta--label__orange {
  color: #fec10c;
}

.respostas__pergunta--label__cp {
    color: #0c8dfe;
}

.respostas__pergunta--result {
    display: flex;
}

.respostas__pergunta--result p {
    margin-right: 3rem;
}

.respostas__normas {
    width: 100%;
    padding: 12px 16px;
    border-left: 1px solid #d9d9d9;
}

.respostas__normas h2 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.respostas__normas h2:first-of-type {
    margin-top: 0;
}
