.areas {
  width: 100%;
  height: 100vh;
}

.areas__container {
  display: flex;
  width: 100%;
}

.areas__container--cap {
  width: 100%;

  padding-right: 2rem;
  border-right: 1px solid #00000029;
}

.areas__container--areas {
  width: 100%;

  padding-left: 2rem;
}

.areas__container--cap__title {
  font-weight: 300;
}

.areas__container--areas__title {
  font-weight: 300;
}
